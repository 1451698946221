import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import planet4 from "../../assets/img/planet4.png";

const Planet4 = (props) => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={props.speed}>
          <img src={planet4} className={`${props.className}`} />
        </Parallax>
      </ParallaxProvider>
    </>
  );
};

export default Planet4;
