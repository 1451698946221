import "./App.css";
import Title from "./components/Title";
import Menu from "./components/Menu";
import About from "./components/About";
import HowMint from "./components/HowMint";
import RoadMap from "./components/RoadMap";
import Team from "./components/Team";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="App">
            <Menu />
            <Title />
            <About />
            <HowMint />
            {/* <RoadMap /> */}
            <Team />
            <Footer />
        </div>
    );
}

export default App;
