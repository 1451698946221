import React, { useState, useEffect } from "react";
import "./Title.css";

import Planet1 from "./PlanetsParallax/Planet1";
import Planet3 from "./PlanetsParallax/Planet3";
import Planet4 from "./PlanetsParallax/Planet4";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import gen3 from '../assets/img/IMG_2891.png'

const Title = () => {
    const [countdown, setCountdown] = useState('');
    const targetDate = new Date('2023-07-22T17:00:00+01:00');
    // const [webhookCalled, setWebhookCalled] = useState(false);
    // const targetDate2 = new Date('2023-07-22T17:46:00+02:00');
    // let counter = 0;

    useEffect(() => {
    const countdown = () => { 
        const currentDate = new Date();

        const timeDifference = targetDate - currentDate;

      
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
            const seconds = Math.floor((timeDifference / 1000) % 60);
    
    
            const countdownText = `${days} days, ${hours} hours, ${minutes} min., ${seconds} sec.`;
    
            setCountdown(countdownText);
          
    };

    const interval = setInterval(countdown, 1000);

    // Zatrzymaj odliczanie po zniszczeniu komponentu
    return () => clearInterval(interval);

}, [])

function callWebhook() {
    const webhookURL = "https://discord.com/api/webhooks/1132338361001058425/3_wE5i5avCXfij5V98h-HDvHlSLx-BfF_QRAiNf30dFg8rPIGEBghAK1DhGtiwfjKuJI";
    const messageData = {
      content: "Dear @everyone \n\nRatz holders the wait is over! Pick and post your numbers below and we will dm you the offers!",
    };
  
    fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(messageData),
    })
      .then((response) => console.log("Webhook called:", response))
      .catch((error) => console.error("Error calling webhook:", error));
  }

    return (
        <section id="title">
            <Planet1 speed={40} className={`pos1`} />
            <Planet1 speed={-50} className={`pos2`} />
            <Planet3 speed={-40} className={`pos4`} />
            <Planet4 speed={-20} className={`pos5`} />

          
            <img src={gen3} id="gen3"/>
            <span id="countdown">{countdown}</span>
           
            {/* <div id="title__header">
                <span>SPACE RATZ NFT</span>
            </div>
            <Button className="button__mint">
                <Link to="/gen2" target="_blank">
                    GEN 2
                </Link>
            </Button> */}
        </section>
    );
};

export default Title;
