import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import rocket from '../../assets/img/rakieta.png'

const Rocket = (props) => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={props.speed}>
          <img src={rocket} className={`${props.className}`} />
        </Parallax>
      </ParallaxProvider>
    </>
  )

}

export default Rocket;
