import React from 'react'

import { Navbar, Container, Nav } from 'react-bootstrap'
import "./Menu.css"
import chia from '../assets/img/chia.png'
import { Link } from "react-scroll";

const Menu = () => {
    return (
        <section id='menu__class'>
            <Navbar id='menu' collapseOnSelect expand="lg" variant="dark" fixed="top">
                <Container id="container">
                    <Navbar.Brand className='menu__brand'>Space RatZ NFT</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto menu__links" >
                            <Nav.Link className='menu__link'>
                                <Link to='about' smooth={true} duration={1000}>About</Link>
                            </Nav.Link>
                            <Nav.Link className='menu__link'>
                                <Link to='mint__class' smooth={true} duration={1000}>How to mint</Link>
                            </Nav.Link>
                            {/* <Nav.Link className='menu__link'>
                                <Link to="roadmap__class" smooth={true} duration={1000}>Roadmap</Link>
                            </Nav.Link> */}
                            <Nav.Link className='menu__link'>
                                <Link to='team' smooth={true} duration={1000}>Team</Link>
                            </Nav.Link>
                            <Nav.Link href="/gen2" target="_blank">gen2</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="http://discord.gg/Bt6fvmde2h"><button className='menu__discord'>join discord</button></Nav.Link>
                            <Nav.Link href="http://chia.net" target="_blank"><img src={chia} className="menu__chia" /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    )
}

export default Menu