import React from "react";
import "./HowMint.css";
import Stars from "./Stars";
import Planet2 from "./PlanetsParallax/Planet2";
import chia from "../assets/img/chia.png";
import { Link } from "react-router-dom";
const HowMint = () => {
    return (
        <section className="mint__class" id="mint">
            <Stars />
            <div className="mint__img">
                <img src={chia} />
            </div>
            <h1 className="mint__h1">HOW TO MINT</h1>
            <div className="mint__text">
                GEN1 & GEN2 you can purchase on the secondary market 
            </div>
            <div className="mint__text__link">
                <a href="https://mintgarden.io/profile/space-ratz-nft-3461dc504bea934aa500f5635ecbd713fc00c84e2d9ba704af120d983a827212?tab=collections">
                    Mint Garden
                </a>
            </div>
            <div className="mint__text__link">
                <a href="https://dexie.space/offers/col1jvfuzwe963gdgchl8vhgz0fjjq5gm6dcrujwrxz9nd30ew7xppvskt5upq/xch">Dexie.Space</a>
            </div>
            <div className="mint__text__icons">
            IF you already OWN a RATZ NFT, please DM @smq or @chudson on discord for a verification and role assignment!  Or try to /verify (and mintgarden bot will do the justice just make sure NFT is in your DID)
                {/* <br />
                <span className="span__if">
                    *If you already OWN a RATZ token, please DM @smq or @chudson
                    on discord for a verification and role assignment!
                </span> */}
            </div>

            <Planet2 speed={-20} className={`pos55`} />
        </section>
    );
};

export default HowMint;
