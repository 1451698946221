import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import Stars from "./components/Stars";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gen2 from "./components/Gen2";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Stars />
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/gen2" element={<Gen2 />}></Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
