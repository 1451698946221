import React from 'react'

import Rocket from './PlanetsParallax/Rocket'
import './About.css'
import rat from '../assets/img/logo_gif.gif'

const About = () => {
    return (
        <section id="about">
            <h1 className="about__h1">about</h1>
            <Rocket className={`pos10`} speed={40} />
            <div className="about__class">
                <div className="img__section">
                    <img className="about__img" src={rat} alt='szczur' />
                </div>
                <div className="text__section">
                    <h1 >SPACE RATZ NFT</h1>
                    SpaceRatZ is an NFT OG project on chia blokchain!<br />

            <br />Currently we have TWO Generations of #RATZ 
            <br />We launched our project based on pre-sale token Before NFT1 standard were realised. Third Generation very SOON.

            <br /><br />GENERATION 1
            <br />Collection of ONLY 120 hand drawn NFTs, was sold withing 24hours when Gene Hoffman used the PFP of SpaceRatZ that we made for him as twitter PFP. Mint price was only 1xch but Floor at the secondary market hit 20xch(currently 5xch) at some point! RATZ number 1 belongs to Gene himself and it was First NFT in his DID !

            <br /><br />GENERATION2
            <br />Hand drawn collection of 333 with 6 super legendary personalised PFP Featuring BatGAN made by Seth Jenks Chia Friends creator. Another personalised NFT from this collection belongs to Sargonas, DonDiego, imthehotfire also famous chia youtubers: Digital Spaceport & Poor Investor. Mint price was 2.5xch(holders had discounted price of 2xch)  Floor hit 6xch(currently 3.3xch big shoutout to our whale/OG/friend Back2Games who swept everything under 3xch) In this collection we have male RATZ and female RATZ as well.

            <br /><br />GENERATION3
            <br />Made purely on the community demand. Hand Drawn Collection of 666. In this occasion we reach out to other key projects on chia to create a spaceRatZ in their style 10 collections participated with 1to1 art plus another 3 1to1 by us! Also we have 3 different characters in this collection. ALL ROYALITIES from this collection will be donated to <a href='https://www.mind.org.uk/'>https://www.mind.org.uk/</a> lets fight mental health together ! Early mint access to RATZ holders and discounted price of 1xch. Public mint next day with price of 1.25 xch
                            </div>
                        </div>
                    </section>
    )
}

export default About