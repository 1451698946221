import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import earth from "../../assets/img/earth.png";

const PlanetGen2_1 = (props) => {
    return (
        <>
            <img src={earth} className={`${props.className}`} />
        </>
    );
};

export default PlanetGen2_1;
