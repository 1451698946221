import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import planet3 from "../../assets/img/planet3.png"

const Planet3 = (props) => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={props.speed}>
          <img src={planet3} className={`${props.className}`} />
        </Parallax>
      </ParallaxProvider>
    </>
  )
}

export default Planet3;
