import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import rocketGen2 from "../../assets/img/rocket2.png";

const RocketGen2 = (props) => {
    return (
        <>
            <ParallaxProvider>
                <Parallax speed={props.speed}>
                    <img src={rocketGen2} className={`${props.className}`} />
                </Parallax>
            </ParallaxProvider>
        </>
    );
};

export default RocketGen2;
