import React from "react";

import "./Gen2.css";

import PlanetGen2_1 from "./PlanetsParallax/PlanetGen2_1";
import Lady from "./PlanetsParallax/Lady";
import RocketGen2 from "./PlanetsParallax/RocketGen2";
import Stars from "./Stars";
const About = () => {
    return (
        <div id="gen2">
            <Stars />
            <h1>SCROLL DOWN</h1>
            <br /> <br /> <br /> <br /> <br />
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br />
            <p className="text1">
                A long long time ago in a galaxy far away <br /> brave RATZ
                fought for more space
            </p>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            <p className="text2">
                {" "}
                Once they thought maybe they should have a brake <br /> so they
                would think to go down to the earth
            </p>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br />
            <p className="text3">
                {" "}
                Earth is great place for having fun <br /> And the best female
                ratz as everyone tells
            </p>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br />
            <p className="text4">
                Get some Silver Branch beer <br /> And live couple of days like
                humans (traits)
            </p>
            <RocketGen2 speed={-2000} className={`rocketgen2`} />
            <p className="text5">
                SpaceRatZ on the EARTH
                <h6>generation 2</h6>
            </p>
            <Lady className={`lady`} />
            <PlanetGen2_1 className={`earth`} />
        </div>
    );
};

export default About;
