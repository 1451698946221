import React from "react";
import "./Footer.css";
import { FaTwitter, FaDiscord, FaInstagram, FaTiktok } from "react-icons/fa";
import { Button } from "react-bootstrap";

const Footer = () => {
    return (
        <section className="footer__class">
            <div className="footer__container">
                <div>
                    <h1>SPACE RATZ NFT</h1>{" "}
                </div>
                <div className="footer__button">
                    <p>
                    SpaceRatZNFT One of the first original projects on chia blokchain ! 
                   <br />  Three generations 
                   <br />Gen1 total of 120 NFTs 
                   <br />Gen2 total of 333 NFTs 
                   <br />Gen3 total of 666 NFTs (Upcoming)
                   <br />Additional small UNMINTED Collection with only 14 
                   <br />GEN1 & GEN2 sold withing 1 day before NFT1 standard were launched on chia 
                   <br /><a href="https://www.youtube.com/watch?v=TZgOxvTGqe0&t=418s">spaceratz with Digitalspaceport</a>
                   <br /><a href="https://www.youtube.com/watch?v=AWrMY5cf4vk">spaceratz with Poor investor</a>
                   <br /><a href="https://www.youtube.com/watch?v=MqwCsISLlcc">spaceratz with chia in china</a>
                    </p>
                    <Button className="button__mint__footer">
                        <a
                            href="mailto:spaceratz@spaceratznft.xyz"
                            style={{ textDecoration: "none" }}
                        >
                            contact us
                        </a>
                    </Button>
                </div>

                <div className="footer__icons">
                    <div className="footer__icon">
                        <a
                            href="https://twitter.com/SpaceRatZNFT"
                            target="_blank"
                        >
                            <FaTwitter size={"2.5em"} />
                        </a>
                    </div>
                    <div className="footer__icon">
                        <a
                            href="https://www.tiktok.com/@spaceratznft"
                            target="_blank"
                        >
                            <FaTiktok size={"2.5em"} />
                        </a>
                    </div>
                    <div className="footer__icon">
                        <a
                            href="https://www.instagram.com/spaceratznft"
                            target="_blank"
                        >
                            <FaInstagram size={"2.5em"} />
                        </a>
                    </div>
                </div>
                <div className="footer__info">Space Ratz NFT @ 2022</div>
            </div>
        </section>
    );
};

export default Footer;
