import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import lady from "../../assets/img/lady.png";

const Lady = (props) => {
    return (
        <>
            <img src={lady} className={`${props.className}`} />
        </>
    );
};

export default Lady;
