import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import planet7 from "../../assets/img/planet7.png"

const Planet7 = (props) => {
    return (
        <>
            <ParallaxProvider>
                <Parallax speed={props.speed}>
                    <img src={planet7} className={`${props.className}`} />
                </Parallax>
            </ParallaxProvider>
        </>
    );
}

export default Planet7;
