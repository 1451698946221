import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import planet2 from "../../assets/img/planet2.png"

const Planet2 = (props) => {
    return (
        <>
            <ParallaxProvider>
                <Parallax speed={props.speed}>
                    <img src={planet2} className={`${props.className}`} />
                </Parallax>
            </ParallaxProvider>
        </>
    )
}

export default Planet2;
