import React from 'react'
import './Stars.css'
const Stars = () => {
    return (
        <div id="starss" style={{ height: 0 }}>
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </div>
    )
}

export default Stars