import React from 'react'
import './Team.css'

// import mate from '../assets/img/mate.png'
import chudson from '../assets/img/chudson.png'
import smq from '../assets/img/img.png'
import theHH from '../assets/img/theHH.png'
import janek from '../assets/img/janeczek.png'
import Planet7 from './PlanetsParallax/Planet7'

const Team = () => {
    return (
        <section className="team__class" id="team">
            <h1 className="team__h1">team</h1>
            <Planet7 className={`pos1`} speed={10} />
            <div className="team__imgs">
                <div className="team__user">
                    <img className="team__img" src={theHH} alt="theHH" />
                    <span className='mobile__block'>
                        <span>theHH</span>
                        <h6 className='team__name'>artist</h6>
                    </span>
                </div>
                <div className="team__user">
                    <img className="team__img" src={smq} alt="smq" />
                    <span className='mobile__block'>
                        <span>smq</span>
                        <h6 className='team__name'>founder</h6>
                    </span>
                </div>
                <div className="team__user">
                    <img className="team__img" src={chudson} alt="chudson" />
                    <span className='mobile__block'>
                        <span>chudson</span>
                        <h6 className='team__name'>chief of communications</h6>
                    </span>
                </div>
                <div className="team__user">
                    <img className="team__img" src={janek} alt="janeczek" />
                    <span className='mobile__block'>
                        <span>janeczek</span>
                        <h6 className='team__name'>web developer</h6>
                    </span>
                </div>
               
                {/* <div className="team__user">
                    <img className="team__img" src={mate} alt="mate" />
                    <span className='mobile__block'>
                        <span>mate</span>
                        <h6 className='team__name'>communications</h6>
                    </span>
                </div> */}
            </div >
        </section >
    )
}

export default Team